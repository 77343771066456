import Table from "./Table";
import routes from '../routes';
import { makeStyles } from "@mui/styles";
// import { FaSearch } from "react-icons/fa";
import PageLoader from './PageLoader';
// import debounce from "lodash.debounce";
import { CgDanger } from "react-icons/cg";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGallery, addGalleryImage } from "../redux/event/event.slice";

const heading = ["SNo.", "Image", "Action"];

const useStyles = makeStyles({
    searchInput: {
        marginBottom: 16,
    },
    searchIcon: {
        right: 16,
        top: 4
    },
    arertHeading: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold'
    },
    alertDetail: {
        fontSize: 17,
        textAlign: 'center',
    }
});

const GalleryImages = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [url, setUrl] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [imageUrl, setImageUrl] = useState("");
    const [isModalDelete, setModalDelete] = useState(false);

    const { isLoader, galleryData } = useSelector((state) => state.event);

    useEffect(() => {
        dispatch(getGallery({limit, offset}));
    },[limit, offset, dispatch]);

    // const debouncedSave = useMemo(() => 
    //     debounce((value) => setSearchText(value), 1000), 
    //     [setSearchText]
    // );

    // const handleSearchText = useCallback((event) => {
    //     if(limit === 10 && offset === 0){
    //         debouncedSave(event.target.value);
    //     }
    //     else{
    //         setLimit(10);
    //         setOffset(0);
    //     }
    // }, [limit, offset, debouncedSave]);

    const nextClicked = (l, o) => {
        setLimit(l+10);
        setOffset(o+10);
    }

    const prevClicked = (l, o) => {
        setLimit(l-10);
        setOffset(o-10);
    }

    const fullImage = (url) => {
        setUrl(url);
        setModalDelete(false);
    }

    const deleteImageClicked = (url) => {
        setImageUrl(url);
        setModalDelete(true);
    }

    const confirmClicked = () => {
        const imageArray = galleryData[0].image;
        const updatedArray = imageArray.filter(item => item !== imageUrl);
        const body = {
            id: "673defde02008af3b8e5ec62",
            image: updatedArray
        }
        const res = dispatch(addGalleryImage(body));
        res?.then((r) => {
            if(r?.payload.statusCode === 200){
                toast.success("Image Deleted");
                dispatch(getGallery({limit, offset}));
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    if(isLoader) return ( <PageLoader />)
    
    return (
        <>
            <div className="container mt-80">
                {/* <div className="position-relative">
                    <div className={`${classes.searchIcon} position-absolute`}>
                        <FaSearch />
                    </div>
                    <input
                        type="text"
                        onChange={handleSearchText}
                        className={`${classes.searchInput} form-control`}
                        placeholder="Search for image"
                    />
                </div> */}
                <Table 
                    data={galleryData && galleryData[0]?.image}
                    heading={heading}
                    limit={limit}
                    offset={offset}
                    tableFor="images"
                    isBtnCreate={true}
                    btnText="Add New Image"
                    totalCount={galleryData && galleryData[0]?.image?.length}
                    fullImage={(url) => fullImage(url)}
                    btnRoute={routes.add_gallery_img}
                    deleteImage={(url) => deleteImageClicked(url)}
                    previous={(limit, offset) => prevClicked(limit, offset)}
                    next={(limit, offset) => nextClicked(limit, offset)}
                />
            </div>
            <div
                id="modal"
                className="modal fade" 
                data-bs-keyboard="false" 
                aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${!isModalDelete && 'modal-xl'}`}>
                    {isModalDelete ?
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 fw-bold text-danger">
                                    Confirm
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body user-detail">
                                <div className="text-center">
                                    <CgDanger fontSize={48} className="text-danger" />
                                </div>
                                <p className={`${classes.arertHeading} text-danger`}>
                                    Are you sure ?
                                </p>
                                <p className={classes.alertDetail}>
                                    You want to delete the image.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    data-bs-dismiss="modal"
                                    className="btn btn-secondary">
                                    Cancel
                                </button>
                                <button
                                    data-bs-dismiss="modal"
                                    onClick={confirmClicked}
                                    className="btn btn-danger">
                                    Proceed
                                </button>
                            </div>
                        </div>
                        :
                        <div>
                            <img 
                                src={url} 
                                alt="" 
                                className="w-100 rounded" 
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default GalleryImages